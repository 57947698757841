<template>
    <div class="com-container" >
        <div class="com-chart" ref="left1_ref"></div>
    </div>
</template>

<script>
    export default{
        name:"left1",
        data() {
            return {
                chartInstance:null,
                // allData:null,    //服务器返回的数据
            };
        },
        mounted() {
            this.initChart()
            this.getData()
            window.addEventListener('resize',this.screenAdapter)
            //页面加载完成后主动进行屏幕的适配
            this.screenAdapter()
        },
        destroyed(){
            //在组件销毁的时候需要将监听器取消
            window.removeEventListener('resize',this.screenAdapter)
        },
        methods: {
            //初始化echartInstance对象
            initChart(){
                this.chartInstance = this.$echarts.init(this.$refs.left1_ref)
                //对图表初始化配置的控制
                const initOption = {
                    title:{
                        text:'商家销售统计',
                        left:20,
                        top:20
                    },
                    grid:{
                        top:'20%',
                        left:'3%',
                        right:'6%',
                        bottom:'3%',
                        containLabel:true//距离包含坐标轴上的文字
                    },
                    xAxis:{
                        type:'value'
                    },
                    yAxis:{
                        type:'category',
                    },
                    series:[
                        {
                            type:'bar',
                        }
                    ]
                }
                this.chartInstance.setOption(initOption)
            },
            //获取服务器数据
            async getData(){
                // const {data:ret} = await this.$http.get('yourUrl')
                // console.log(ret)
                // this.allData = ret
                this.updateChart()
            },
            //更新图表
            updateChart(){
                // const left1Names = this.allData.map((item) => {
                //     return item.name
                // })
                // const left1Values = this.allData.map((item) => {
                //     return item.value
                // })
                const dataOption = {
                    yAxis:{
                        data:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    series:[
                        {
                            data:[120, 200, 150, 80, 70, 110, 130]
                        }
                    ]
                }
                this.chartInstance.setOption(dataOption)
            },
            //当浏览器的大小发生变化的时候会调用的方法，来完成屏幕的适配
            screenAdapter(){
                // console.log(this.$refs.left1_ref.offsetWidth)
                const titleFontSize = this.$refs.left1_ref.offsetWidth / 100 *3.6
                // console.log(titleFontSize)
                //和分辨率相关的配置项
                const adapterOption = {
                    title:{
                        textStyle:{
                            fontSize:titleFontSize,
                        }
                    }
                }
                this.chartInstance.setOption(adapterOption)
                //手动调用图表对象的resize才能产生效果
                this.chartInstance.resize()
            }
        },

    }
</script>