<template>
    <div class="com-page">
        <left1></left1>
    </div>
</template>

<script>
    import left1 from '../src/components/left1'
    export default{
        name:"left1Page",
        components: { 
            left1
        },
        data() {
            return {};
        },
        methods: {

        },
        mounted() {
        }
    }
</script>